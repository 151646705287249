import * as React from 'react';
import axios from 'axios';
import { css } from "@emotion/core";
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api } from '../../config';
import PropTypes from 'prop-types';
import UnsubscribeButton from "../../components/unsubscribeButton/unsubscribeButton";
import Redirect from "../../components/Redirect";
import { isLoggedIn } from '../../utils/auth';

import faCheckCircle from '@fortawesome/fontawesome-free-regular/faCheckCircle';
import faTimesCircle from '@fortawesome/fontawesome-free-regular/faTimesCircle';
import Card from './card';

class Mealplan extends React.Component {
  state = {
    error: null,
    success: null,
    loading: null,
  };

  render() {
    const { profile, user } = this.props;
    const isMember =
      false &&
      user.paymentStoreType !== 0 &&
      user.paymentExpirationTimestamp !== 0;
    return isLoggedIn() ? (
      <Card
        titleText={this.props.titleText}
        SVGIcon={this.props.SVGIcon}
        rightNode={
          <div
            css={css`
              font-family: Barlow Condensed;
              font-size: 18px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.17;
              letter-spacing: normal;
              text-align: right;
              color: #fc172f;
              margin: auto 0;
            `}
          >
            {isMember ? (
              <UnsubscribeButton
                user={user}
                buttonText={profile.subscriptionCancelButton.buttonText}
                confirm={{
                  title: profile.subscriptionCancelButton.title,
                  info: profile.subscriptionCancelButton.info,
                  question: profile.subscriptionCancelButton.question,
                  cancelButton: profile.subscriptionCancelButton.cancelButton,
                  confirmButton: profile.subscriptionCancelButton.confirmButton,
                  handleCancelClick: this.props.handleCancelClick,
                }}
                onClick={this.props.showConfirm}
              />
            ) : (
              <button type="submit" className="subscrip-button">
                {profile.joinMeal}
              </button>
            )}
          </div>
        }
      >
        {isMember ? (
          <h2 className="fitplan-member">
            <FontAwesomeIcon icon={faCheckCircle} /> {profile.mealMember}
          </h2>
        ) : (
          <div
            css={css`
              background: #fff;
              margin-top: 20px;
            `}
          >
            <h2 className="fitplan-nonmember">
              <FontAwesomeIcon icon={faTimesCircle} /> {profile.nonMealMember}
            </h2>
          </div>
        )}
        {isMember ? (
          <p className="subscrip-content-block">
            Your <span className="bold">yearly subscription</span> will
            automatically renew on
            <br />
            <span className="bold">
              {format(
                new Date(user.displayExpirationTimeStamp),
                'MMMM do, yyyy'
              )}
            </span>{' '}
            and you'll be charged <span className="bold">XX.XX USD</span>
          </p>
        ) : (
          <p className="subscrip-content-block">{profile.joinMealMessage}</p>
        )}
      </Card>
    ) : (
      <Redirect to="/login" />
    );
  }
}

Mealplan.propTypes = {
  handleCancelClick: PropTypes.func,
  showConfirm: PropTypes.func,
  profile: PropTypes.object,
  user: PropTypes.object,
};

Mealplan.defaultProps = {
  handleCancelClick: () => null,
  showConfirm: () => null,
  profile: {},
  user: {},
};

export default Mealplan;
