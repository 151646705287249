import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from "@emotion/core";
import CrossIcon from '../../pages/account/cross-icon';

const UnsubscribeButton = props =>
  props.user.cancelAtPeriodEnd ? null : (
    <div
      css={css`
        white-space: nowrap;
        display: block;
        text-align: left;
        font-family: league-gothic, sans-serif;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 0.05em;
        margin: 0px;
        background-color: #ffffff;
      `}
    >
      <button
        type="button"
        css={css`
          height: 31.6px;
          font-family: Barlow;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: right;
          color: #fc172f;
          border: none;
          background: transparent;
          display: flex;
          &:hover {
            cursor: pointer;
          }
        `}
        onClick={e => {
          e.preventDefault();
          props.onClick(props.confirm);
        }}
      >
        <div>
          <CrossIcon className="w-3 h-3 md:w-6 md:h-6" />
        </div>
        <span className="-mt-1 md:mt-0 text-base ml-2 md:ml-4 md:text-xl">
          {props.buttonText}
        </span>
      </button>
    </div>
  );

UnsubscribeButton.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  confirm: PropTypes.object,
  user: PropTypes.object,
};

UnsubscribeButton.defaultProps = {
  onClick: () => null,
  user: {},
  confirm: {},
  buttonText: '',
};
export default UnsubscribeButton;
