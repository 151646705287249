import * as React from 'react';

const CrossIcon = ({
  width = '15px',
  height = '15px',
  stroke = '#00FFB3',
  className = '',
  viewBox = '0 0 15 15',
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="#FC172F"
      fillRule="evenodd"
      d="M13.743 1.087c-.4-.4-1-.4-1.4 0l-5.3 5.3-5.3-5.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l5.3 5.3-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l5.3-5.3 5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-5.3-5.3 5.3-5.3c.4-.4.4-1 0-1.4z"
    />
  </svg>
);

export default CrossIcon;
